<template>
  <ValidationObserver v-slot="{ invalid }">
    <form @submit.prevent="submitServices" class="mt-10">
      <div class="mb-5">
        <label class="block mb-2">Date of wake keep</label>
        <ValidationProvider
          name="Date of wake keep"
          rules="required"
          v-slot="{ errors }"
        >
          <input
            type="date"
            placeholder="Date of wake keep"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
            :class="errors[0] ? 'border-red-400 border' : ''"
            v-model="service.date_of_wake_keep"
          />
          <span class="block text-red-400 mt-3" v-if="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="mb-5">
        <label class="block mb-2">Date of funeral</label>
        <ValidationProvider
          name="Date of funeral"
          rules="required"
          v-slot="{ errors }"
        >
          <input
            type="date"
            placeholder="Date of funeral"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
            :class="errors[0] ? 'border-red-400 border' : ''"
            v-model="service.date_of_funeral"
          />
          <span class="block text-red-400 mt-3" v-if="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="mb-5">
        <label for="email" class="block mb-2">Selected services</label>
        <ValidationProvider name="Permissions" v-slot="{ errors }">
          <vSelect :options="all_services" multiple v-model="funeral_services">
            <template v-slot:option="option">
              {{ option.service_name }}: {{ parseCash(option.service_amount) }}
            </template>
            <template slot="selected-option" slot-scope="option">
              {{ option.service_name }}
            </template>
          </vSelect>
          <span class="block text-red-400 mt-3" v-if="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="mb-5">
        <label for="email" class="block mb-2">Selected Products</label>
        <ValidationProvider name="Permissions" v-slot="{ errors }">
          <vSelect
            :options="inventory"
            multiple
            label="inventory_item_name"
            v-model="selected_products"
            @option:selected="selected"
            @option:deselected="deselected"
          />
          <span class="block text-red-400 mt-3" v-if="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div
        class="mb-5"
        v-for="(product, index) in selected_products"
        :key="index"
      >
        <template v-if="products_in_list.includes(product.inventory_item_name)">
          <label class="block mb-2">{{ product.inventory_item_name }}</label>
          <select
            type="text"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
            v-model="service.inventories[product.inventory_item_name]"
          >
            <template v-for="item in list">
              <template v-if="item.name === product.inventory_item_name">
                <template v-for="things in item.items">
                  <option
                    v-for="microThings in things"
                    :key="microThings.id"
                    :value="microThings"
                  >
                    {{ microThings.inventory_type.inventory_types_name }}
                  </option>
                </template>
              </template>
            </template>
          </select>
        </template>
      </div>
      <button
        class="w-full mt-24 text-white py-3 rounded-md bg-opacity-80 transition-all hover:bg-opacity-100 disabled:cursor-not-allowed"
        :class="invalid ? 'bg-gray-300' : 'bg-primary'"
        :disabled="invalid && submitting"
      >
        {{ submitting ? "Please wait..." : "Continue" }}
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  fetchAllItems,
  fetchAllServices,
  fetchAnItem,
} from "@/services/inventory";
import { forthstep } from "@/services/addmission";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { parseMoney } from "@/utils/helpers";

export default {
  props: {
    corpse: {
      required: true,
      type: Object,
    },
  },
  components: {
    vSelect,
  },
  computed: {
    products_in_list() {
      if (this.list.length === 0) return [];
      let result = this.list.map((item) => item.name);
      return result;
    },
  },
  data() {
    return {
      submitting: false,
      funeral_services: [],
      all_services: [],
      inventory: [],
      selected_products: [],
      list: [],
      service: {
        corpse_id: "",
        date_of_wake_keep: "",
        date_of_funeral: "",
        inventories: {},
      },
    };
  },
  methods: {
    selected(option) {
      this.fetchProduct(option[option.length - 1]);
    },
    deselected(option) {
      this.list = this.list.filter(function (obj) {
        return obj.name !== option.inventory_item_name;
      });
      this.list.forEach((el) => {
        el.name != option.name;
      });
    },
    async submitServices() {
      this.service.corpse_id = this.corpse.corpse_id;
      this.submitting = true;
      let inventory = [];
      // First add all inventories in services.inventories
      const special_case_inventory = Object.entries(this.service.inventories);
      special_case_inventory.forEach((item) => {
        console.log(item);
        inventory.push({
          inventory_id: item[1].id,
          amount: item[1].price_per_items,
          name: item[1].inventory_type.inventory_types_name,
          type: "item",
        });
      });
      this.funeral_services.forEach((item) => {
        console.log("item", item);
        inventory.push({
          inventory_service_id: item.id,
          amount: item.service_amount,
          name: item.service_name,
          type: "service",
        });
      });
      console.log(inventory);
      this.service.inventory = inventory;
      try {
        delete this.service.inventories;
        const response = await forthstep(this.service);
        this.$emit("created", response.data);
      } catch (error) {
        this.$toast.error(error.response.data.message || error);
        console.log(error);
      }
      this.submitting = false;
    },
    parseCash(value) {
      return parseMoney(value);
    },
    async fetchProduct(product) {
      try {
        const response = await fetchAnItem(product.id);
        if (response.data.inventory.length !== 0) {
          let obj = { name: "", items: [] };
          obj.name = product.inventory_item_name;
          obj.items.push(response.data.inventory);
          this.list.push(obj);
        } else {
          this.$toast.info(
            `${product.inventory_item_name} does not have any item under it. Please contact the inventory officer.`
          );
          // Remove from seleted product
          this.selected_products.pop();
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    const response = await fetchAllItems();
    this.inventory = response.data.inventory_item;
    const services = await fetchAllServices();
    this.all_services = services.data.inventory_services;
  },
};
</script>
